import React, { useEffect } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import GetMatchedPage from "pages/GetMatchedPage.js";
import PrivacyPolicyPage from "pages/PrivacyPolicyPage.js";

import MainLandingPage from "MainLandingPage.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const RedirectToGoogle = () => {
  useEffect(() => {
    window.location.href = "https://docs.google.com/spreadsheets/d/16LTg9JIe3jgIWxRTYAcs8a5eN3XPREnCtqTM_l748yU/edit?usp=sharing";
  }, []);

  return null; // Since we're redirecting, we don't need to render anything
};

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/" element={<MainLandingPage />} />
          <Route path="/get-matched" element={<GetMatchedPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/bench" element={<RedirectToGoogle />} />
          {/* <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> */}
        </Routes>
      </Router>
    </>
  );
}