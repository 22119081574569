import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

// Update the 'flex-col' to 'flex-row' in 'ThreeColumnContainer'
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row flex-wrap items-center md:items-stretch md:flex-row md:justify-center max-w-screen-xl mx-auto  `}
`;

const Heading = tw.h2`text-3xl font-black tracking-wide text-left mt-32 w-full`;

// Update the width settings for 'Column' to accommodate for smaller screens
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/3 px-6 flex`}
`;


const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-t-2 border-dashed  mt-12`}

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
  )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
  const DecoratorBlob2 = tw(
    SvgDecoratorBlob2
  )`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;


export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      title: "Diverse source of talent",
      description: "global network of remote freelancers, collaborations with top software companies across Europe, Asia, and Australia, and the expert capabilities of SOFT HOUSE's core tech teams, cultivating a rich, dynamic talent pool driving innovation and superior service delivery."
    },
    {
      title: "Streamline hiring process",
      description: " We provide only pre-vetted talent and trusted partners, ensuring high quality. We match top experts with your technology, industry, company culture, and project type, guaranteeing a perfect fit. Moreover, we offer a shortlist of talent under 24 hours for most popular technologies, accelerating your project kick-off."
    },
    {
      title: "Ultimate business support",
      description: "You'll have a dedicated Talent Specialist, who will navigate through the nuances of talent acquisition and management. All facets, including payments, insurance, legal, and administrative tasks, are consolidated under a single monthly invoice. Furthermore, we manage talent performance and provide regular reporting throughout the collaboration, ensuring transparency and success."
    }
  ];

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>Delivering <span tw="text-primary-500">exceptional</span> tech talent <span tw="text-primary-500">swiftly</span> and <span tw="text-primary-500">smoothly</span>.</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob1/>
      <DecoratorBlob2/>
    </Container>
  );
};
