import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import CodeImage from "../../images/code.svg";
import DesignImage from "../../images/design.svg";
import ProjectImage from "../../images/project.svg";
import TeamImage from "../../images/team.svg";

const Container = tw.div`relative`;

// Update the 'flex-col' to 'flex-row' in 'ThreeColumnContainer'
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-row flex-wrap items-center md:items-stretch md:flex-row md:justify-center max-w-screen-xl mx-auto  `}
`;

const Heading = tw.h2`text-3xl font-black tracking-wide text-left w-full`;

// Update the width settings for 'Column' to accommodate for smaller screens
const Column = styled.div`
  ${tw`w-full md:w-1/2 lg:w-1/4 px-6 flex`}
`;


const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: CodeImage,
      title: "Software developers",
      description: "Software Experts, Web & Mobile Developers. AI Researchers. Cloud & Infrastructure Engineers"
    },
    {
      imageSrc: DesignImage,
      title: "Digital designers",
      description: "UI/UX Designers, Illustrators, Branding Experts, Product & Interface Designers"
    },
    {
      imageSrc: ProjectImage,
      title: "IT Project Managers",
      description: "Project Managers, Scrum Masters, Product Owners, Business Analysts"
    },
    {
      imageSrc: TeamImage,
      title: "Cross-Functional Teams",
      description: "Product Teams, Software Development Teams, IT Project Teams"
    }
  ];

  return (
    <Container id="services">
      <ThreeColumnContainer>
        <Heading>Hire with <span tw="text-primary-500">Ease And Confidence.</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
              {card.contact_us? (
                <a href="#contact-us" >Contact us</a>
              ) : ("") }
              
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
