import React from 'react';

class TypeFormComponent extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;

    this.instance.appendChild(script);
  }

  render() {
    return (
      <div 
        data-tf-widget="qJept6DK" 
        data-tf-opacity="100" 
        data-tf-inline-on-mobile 
        data-tf-iframe-props="title=My typeform" 
        data-tf-transitive-search-params 
        data-tf-auto-focus 
        data-tf-medium="snippet" 
        data-tf-full-screen
        ref={el => (this.instance = el)}
      >
      </div>
    );
  }
}

export default TypeFormComponent;
