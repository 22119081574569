import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = tw.div`relative mt-20`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Section = tw.section` -mt-24`;
const Header =  tw.h2`text-3xl font-black tracking-wide text-left`;
const LogosContainer = tw.div`flex flex-wrap justify-center items-center lg:justify-between mt-8`;

const Marked = tw.span`text-primary-500`;

const CompanyLogo = styled.div`
  ${tw` w-48 pt-8 pb-8 border-t-2
  border-b-2 border-dashed`}
`;


const Image = styled.img``;

export default () => {
  return (
    <Section>
      <Container>
        <Content>
        <Header><Marked>Startups</Marked>, <Marked>scale-ups</Marked> and <Marked>enterprises</Marked> build their teams with SOFT HOUSE.</Header>
        <LogosContainer>
          <CompanyLogo>
             <Image src="tmobile-logo.png"  style={{ filter: "grayscale(100%)" }}></Image>
          </CompanyLogo>
          <CompanyLogo>
          <Image src="sii-logo.png"  style={{ filter: "grayscale(100%)", height: "46px", width: "174px" }} ></Image>
          </CompanyLogo>
          <CompanyLogo>
          <Image src="nurofren-logo.svg"  style={{ filter: "grayscale(100%)" }}></Image>
          </CompanyLogo>
        </LogosContainer>
        </Content>
      </Container>
    </Section>
  );
};