import React from "react";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-3.svg";


// tw.div`bg-gray-100 py-8`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto flex flex-col items-center`;
const Heading = tw.h2`text-3xl sm:text-4xl font-bold`;
const StepsContainer = tw.div`mt-12 flex flex-col sm:flex-row items-center justify-center`;
const Step = tw.div`flex flex-col items-center sm:items-start justify-center sm:justify-start max-w-xs mx-4 sm:mx-8`;
const StepNumber = tw.div`text-4xl font-bold text-primary-500`;
const StepTitle = tw.h3`text-lg font-bold`;
const StepDescription = tw.p`text-base text-gray-700 text-center sm:text-left`;

const Marked = tw.span`text-primary-500`;


const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
  )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
  const DecoratorBlob2 = tw(
    SvgDecoratorBlob2
  )`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

  

const SecureGrowthSteps = () => {
  return (
    <Container >
      <Content>
        <Heading><Marked>Secure your growth</Marked> with top tech talent</Heading>
        <StepsContainer>
          <Step>
            <StepNumber>1</StepNumber>
            <StepTitle>Tell us about your business requirements</StepTitle>
            <StepDescription>
              Talk to our advisor about your exact needs, product specifics, and team dynamics. The more we know at this step, the better the future match will be.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>2</StepNumber>
            <StepTitle>Get the shortlist of talent under 24 hours</StepTitle>
            <StepDescription>
              Based on the interview, we will shortlist the top talents best suited for your needs. For most popular technologies you will get it within 24 hours.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>3</StepNumber>
            <StepTitle>Hire and onboard with a money-back guarantee</StepTitle>
            <StepDescription>
              We will onboard the talent and take care of all payments, insurance, reporting, and other dull processes. There is also a 7 days money-back guarantee after the project's kick-off.
            </StepDescription>
          </Step>
        </StepsContainer>
      </Content>
      <DecoratorBlob2/>
    </Container>
  );
};

export default SecureGrowthSteps;