import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { SectionHeading } from "components/misc/Headings";

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <StyledDiv className="App">
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
          <p>Last updated: September 13, 2024</p> 
          <p>This Privacy Policy outlines how GAWRYS HOLDING Sp. z o.o., located at ul. Bema 48, 84-208 Kielno, Poland, processes personal data for marketing purposes.</p> 
          <h1>Introduction</h1> 
          <p>We collect and process personal data that you voluntarily provide when you contact us or agree to be added to our database. This data is used to send you updates about our offers and services.</p> 
          <h2>Data Controller</h2> 
          <p>The data controller responsible for your personal data is GAWRYS HOLDING Sp. z o.o., registered under KRS: 0001107524, NIP: 5882514286, REGON: 528754109, with a share capital of PLN 20,000.</p> 
          <h2>Types of Data Collected</h2> 
          <ul> 
            <li><strong>Personal Data:</strong> Includes your name, email address, phone number, and any other contact information you provide.</li> 
            <li><strong>Usage Data:</strong> Data collected automatically when using our services, such as IP address, browser type, and visit duration.</li> 
         </ul> 
         <h2>Purpose of Data Collection</h2> 
         <p>We collect your personal data for the following purposes:</p> 
         <ul> 
            <li>To send you updates and marketing offers related to our services.</li> 
            <li>To improve our marketing strategies based on user preferences and behavior.</li> 
        </ul> 
        <h2>Legal Basis for Processing</h2> 
        <p>Your personal data is processed based on your explicit consent, which you provide when you agree to be added to our database.</p> 
        <h2>Retention of Data</h2> 
        <p>Your personal data will be stored for as long as necessary to fulfill the purposes outlined in this policy or as required by law.</p> 
        <h2>Your Rights</h2> 
        <p>You have the right to access, rectify, delete, or restrict the processing of your personal data at any time. You can withdraw your consent by contacting us at the details provided below.</p> 
        <h2>Contact Information</h2> 
        <p>If you have any questions regarding this Privacy Policy, please contact us at:</p> 
        <p>Email: contact@soft-house.eu</p> 
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </StyledDiv>
  );
};
