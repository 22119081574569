import React, { useState, useEffect } from 'react';
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";

const Div = tw.div``;
const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Hr = tw.div`mt-3`;
const Row = tw.div`flex items-center justify-center flex-col px-8`
const LogoContainer = tw.div`text-center`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;
const Email = tw.a`font-extrabold`;
const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`;
const HireTechTalent = tw.a`bg-primary-800  py-2 px-4  text-white font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

// @media (min-width: 700px) {
 
// }

export default () => {
  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);
  
    useEffect(() => {
      const mediaQueryList = window.matchMedia(query);
      const listener = (event) => setMatches(event.matches);
      
      mediaQueryList.addListener(listener);
      return () => mediaQueryList.removeListener(listener);
    }, [query]);
  
    return matches;
  };

  const isRowBased = useMediaQuery('(min-width: 700px)');

const LogoContainerStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  flexDirection: isRowBased ? 'row' : 'column'
};
  
  return (
    <Container>
      <Content>
        {/* <Row> */}
      <LogoContainer style={LogoContainerStyles}>
        
        <Div>
      <LogoText>SOFT HOUSE LTD</LogoText>
            VAT: GB432066718 <br/>
            321-323 HIGH ROAD<br/>
            ESSEX, RM6 6AX<br/>
            UNITED KINGDOM <br/> <br/>
            <Email href="mailto:hello@soft-house.uk">hello@soft-house.uk</Email>
            </Div>
<Div>
<LogoText>GAWRYS HOLDING SP. Z O.O.</LogoText>
            VAT EU: PL5882514286 <br/>
            ul. Bema 48<br/>
            Kielno, 84-208<br/>
            POLAND <br/> <br/>
            <Email href="mailto:contact@soft-house.eu">contact@soft-house.eu</Email>
            </Div>
          </LogoContainer>
          {/* </Row> */}
        <Row>
          <SocialLinksContainer>
            {/* <SocialLink href="https://web.facebook.com/devsoldiers">
              <FacebookIcon />
            </SocialLink> */}
            <SocialLink href="https://www.linkedin.com/company/softhouseglobal/">
              <LinkedinIcon />
            </SocialLink>
          </SocialLinksContainer>
          <LinksContainer>
            <Link href="/#services">Services</Link>
            <Link href="/#our_expertise">Global talent pool</Link>
            <Link href="/#testimonials">Testimonials</Link>
            <Link href="https://blog.soft-house.eu">Blog</Link>
            <Link href="https://career.soft-house.eu">Get Hired</Link>
            <HireTechTalent href="/get-matched">Hire Tech Talent</HireTechTalent>
          </LinksContainer>
          <CopyrightText>
          <Link href="/privacy-policy">Privacy policy</Link><br/><br/>
            &copy; Copyright 2022, SOFT HOUSE All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
