import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import Features2 from "components/features/DashedBorderSixFeatures2";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ProfileThreeColGrid from "components/cards/ProfileThreeColGrid";
import ThreeColSlider from "components/cards/ThreeColSlider";
import ThreeColSimpleWithImageAndDashedBorder from "components/blogs/ThreeColSimpleWithImageAndDashedBorder";
import Steps from 'components/Steps';
import Hiring from 'components/Hiring';
import CooperateWithUs from "components/CooperateWithUs";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import ExecuteYourVision from "components/ExecuteVision";
const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

export default () => (
  <StyledDiv className="App">
    <Hero />
    <CooperateWithUs/>
    <Features />
    <Features2/>
    <MainFeature2 />
    <ThreeColSlider/>
    <Steps/>
    <Hiring/>
    <ExecuteYourVision/>
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our customers are saying about our outsourcing services."
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "/kamil_l.png",
          quote:
            "We were very impressed with their consistent communication, project management, and responsiveness to our issues. They delivered my social media commerce platform as for my exceptations.",
          customerName: "Kamil L.",
          customerTitle: "CEO, VVES Limited"
        },
        {
          imageSrc:
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "ff_avatar.png",
          quote:
            "I've been consistently impressed by the professionalism and expertise of this team. Their ability to understand our requirements and deliver tailored solutions sets them apart from others.",
          customerName: "JOHN WILLIAMS",
          customerTitle: "CTO, DIGITALVISION INC."
        },
        {
          imageSrc:
          "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "/rf_avatar.png",
          quote:
            "Choosing this software house for our project was one of the best decisions we made. They provided exceptional support and expertise throughout the entire development process.",
          customerName: "RYAN FOSTER",
          customerTitle: "CEO, TECHSTRIDE SOLUTIONS"
        }
      ]}    
      textOnLeft={true}
    />
    <ThreeColSimpleWithImageAndDashedBorder/>
  
    <Footer />
    </StyledDiv>
);
