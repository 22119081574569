import React from "react";
import tw from "twin.macro";

import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-3.svg";


const Container = tw.div`flex flex-wrap justify-center text-black mt-20`;
const Column = tw.div`w-full md:w-1/2 lg:w-1/4 p-4 border-t-2 ml-8 border-dashed`;
const Header = tw.h2`text-2xl font-bold mb-4`;
const Icon = tw.div`w-16 h-16 mb-4`;
const Title = tw.h3`text-xl font-bold mb-2`;
const Description = tw.p`text-gray-700`;

const Marked = tw.span`text-primary-500`;


const DecoratorBlob1 = tw(
    SvgDecoratorBlob1
  )`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
  const DecoratorBlob2 = tw(
    SvgDecoratorBlob2
  )`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

  
const FlexibleHiring = () => {
  return (
    <Container>
   
        <Column>

        <Header><Marked>Flexible hiring</Marked> according to your needs</Header>
    </Column>
      <Column>

        <Header>Short-term projects and quick collaborations</Header>
    </Column>
      <Column>
 
        <Header>Long-term contracts and team extension</Header>
      </Column>
      <Column>

        <Header>Team scaling for startups and enterprises</Header>
      </Column>
      <Column>

        <Header>Building cross-functional tech teams from scratch</Header>
      </Column>
      <Column>

        <Header>Establishing developer centres in CEE</Header>
      </Column>
    </Container>
  );
};

export default FlexibleHiring;