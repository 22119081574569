import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.div`
  ${tw`bg-primary-800 text-white py-10 mt-20  border-dashed border-4 border-white`}
`;

const Content = styled.div`
  ${tw`mx-auto flex flex-col lg:items-start sm:items-center md:items-center justify-center md:ml-0 lg:ml-10`}
`;

const Heading = styled.h2`
  ${tw`lg:text-4xl font-bold mb-8 text-center lg:text-left`}
`;

const Button = styled.button`
  ${tw`bg-white text-black py-3 px-8  rounded-full text-lg font-bold hover:bg-primary-600 hover:text-white transition-all duration-300 mt-8`}
`;

const handleClick = () => {
    window.location.href = "/get-matched";
  }


const ExecuteYourVisionSection = () => {
  return (
    <Container>
      <Content>
        <Heading>Execute your vision with trusted tech talent from world company.</Heading>
        <Button  onClick={handleClick}>Consult your project</Button>
      </Content>
    </Container>
  );
};

export default ExecuteYourVisionSection;